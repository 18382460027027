import React, { FC } from "react"
import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"

const progetti: FC = () => {
  return (
    <Layout>
      <div className="single-project">
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Fluckiger/_DSC1980.jpg"}
            alt="Alambicco"
          />
        </div>
        <h3 className="single-project-picture-landscape">
          Flückiger 2021
          <h5 className="single-project-text">
            Casa monofamiliare costruita in zona edificabile a Locarno Monti,
            sfruttando la superficie inutilizzata del giardino sul quale il
            proprietario aveva già ristrutturato la sua abitazione primaria una
            decina di anni prima. La forma della nuova casa e del suo tetto è
            stata adattata alla tipologia delle altre abitazioni presenti nel
            quartiere, cercando di sfruttare al massimo la conformazione del
            terreno e la possibilità di poter fruire della stupenda vista sul
            lago Maggiore. Si è trattato di costruire gli accessi in pietra, le
            murature interrate in beton e la costruzione prefabbricata in legno
            fuori terra, cercando di limitare gli spostamenti di materiale.
            Tutti i trasporti sono stati eseguiti con l’elicottero. Nel rispetto
            del vicinato sono stati organizzati interventi da circa 30 minuti
            una volta alla settimana. Nella costruzione si è cercato di evitare
            materiali provenienti dalla lavorazione del petrolio, l’isolazione
            termica è quindi stata eseguita con Foamglas, fibra di legno e
            sughero.
          </h5>
        </h3>
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Fluckiger/_DSC2050.jpg"}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Fluckiger/_DSC1944.jpg"}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Fluckiger/_DSC1954.jpg"}
            alt="Alambicco"
          />
        </div>
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Fluckiger/_DSC1968.jpg"}
            alt="Alambicco"
          />
        </div>

        <div>
          <StaticImage
            src={"../../assets/images/Fluckiger/_DSC2010.jpg"}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Fluckiger/_DSC2023.jpg"}
            alt="Alambicco"
          />
        </div>
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Fluckiger/_DSC2015.jpg"}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Fluckiger/_DSC2067.jpg"}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Fluckiger/_DSC2078.jpg"}
            alt="Alambicco"
          />
        </div>
      </div>
    </Layout>
  )
}

export default progetti
